import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './onboarding-flow.css';

const OnboardingFlow = () => {
  const location = useLocation();
  const user = location.state?.user;

  const [step, setStep] = useState(1);
  const [struggles, setStruggles] = useState('');
  const [interests, setInterests] = useState('');

  const handleNextStep = () => {
    axios.put(`${process.env.REACT_APP_API_URL}/api/users/${user.display_name}/user-profile/`, { struggles })
      .then(response => {
        setStep(2);
      })
      .catch(error => {
        console.error("Error saving struggles", error);
      });
  };

  const handleFinish = () => {
    axios.put(`${process.env.REACT_APP_API_URL}/api/users/${user.display_name}/user-profile/`, { interests })
      .then(response => {
        // Redirect to landing or profile page after submission
        window.location.href = '/landing';
      })
      .catch(error => {
        console.error("Error saving interests", error);
      });
  };

  return (
    <div className="onboarding-container">
      {step === 1 && (
        <div className="modal">
          <h2>Hi {user.display_name}. What are you or have you struggled with in the past?</h2>
          <textarea
            className="textarea-input"
            value={struggles}
            onChange={(e) => setStruggles(e.target.value)}
            placeholder="Describe your struggles..."
          />
          <button className="modal-button" onClick={handleNextStep}>Next</button>
        </div>
      )}

      {step === 2 && (
        <div className="modal">
          <h2>What are your interests or what interests did you have in the past?</h2>
          <textarea
            className="textarea-input"
            value={interests}
            onChange={(e) => setInterests(e.target.value)}
            placeholder="Describe your interests..."
          />
          <button className="modal-button" onClick={handleFinish}>Finish</button>
        </div>
      )}
    </div>
  );
};

export default OnboardingFlow;
