import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import NavBar from './components/nav-bar';
import LandingPage from './components/landing-page';
import SignUpPage from './components/sign-up';
import ChatPage from './components/chat/chat-page';
import MessageBoard from './components/message-boards/message-board';
import OnboardingFlow from './components/onboarding-flow';
import LoginPage from './components/login';
import ProfilePage from './components/profile';

function App() {
    return (
        <Router>
        <NavBar />
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/onboarding-flow" element={<OnboardingFlow />} />
        <Route path="/profile" element={<ProfilePage />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/chat/:community" element={<ChatPage />} />
            <Route path="/message-boards/:boardName" element={<MessageBoard />} />
        </Routes>
    </Router>
    );
}

export default App;