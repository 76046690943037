import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './message-board.css';

// Reply Component to render each reply
const Reply = ({ reply, depth, handleReply, replyText, setReplyText, boardName }) => {
    const [expandedReplyIds, setExpandedReplyIds] = useState([]); // Track expanded replies

    const toggleExpandReply = (replyId) => {
        if (expandedReplyIds.includes(replyId)) {
            setExpandedReplyIds(expandedReplyIds.filter(id => id !== replyId));
        } else {
            setExpandedReplyIds([...expandedReplyIds, replyId]);
        }
    };

    return (
        <div style={{ marginLeft: depth * 20 + 'px' }}> {/* Indent based on reply depth */}
            <div className="reply-item">
                <strong>{reply.user_display_name}</strong>
                {reply.message?.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
                <button 
                    className="expand-post-button"
                    onClick={() => toggleExpandReply(reply.id)}>
                    {expandedReplyIds.includes(reply.id) ? 'Collapse' : 'Expand'}
                </button>
                {expandedReplyIds.includes(reply.id) && (
                    <>
                        {reply.replies && reply.replies.map((childReply, index) => (
                            <Reply
                                key={index}
                                reply={childReply}
                                depth={depth + 1}
                                handleReply={handleReply}
                                replyText={replyText}
                                setReplyText={setReplyText}
                                boardName={boardName}
                            />
                        ))}
                        <textarea
                            value={replyText[reply.id] || ''}
                            onChange={(e) => setReplyText({ ...replyText, [reply.id]: e.target.value })}
                            placeholder="Add a reply"
                            rows={2}
                            className="reply-input"
                        />
                        <button className="reply-button" onClick={() => handleReply(reply.id)}>Reply</button>
                    </>
                )}
            </div>
        </div>
    );
};

const MessageBoard = () => {
    const { boardName } = useParams(); // Capture the board name from the URL
    const navigate = useNavigate();
    const location = useLocation(); // Use location to capture state
    const displayName = location.state?.displayName || 'Anonymous';

    const [posts, setPosts] = useState([]);
    const [newPostTitle, setNewPostTitle] = useState(''); // Add a title for the new post
    const [newPostMessage, setNewPostMessage] = useState(''); // Add a message for the new post
    const [replyText, setReplyText] = useState({});
    const [expandedPostIds, setExpandedPostIds] = useState([]); // Track which posts are expanded

    useEffect(() => {
        // Fetch the posts for the board
        if (boardName) {
            const url = `${process.env.REACT_APP_API_URL}/api/message-boards/communities/ydy/${boardName}/posts`;
            axios.get(url)
                .then(response => {
                    setPosts(response.data);
                })
                .catch(error => {
                    console.error('Error fetching posts:', error);
                });
        }
    }, [boardName]);

    const handleAddPost = () => {
        if (newPostTitle.trim() && newPostMessage.trim()) {
            const post = {
                user_display_name: displayName,
                title: newPostTitle,  // Include title in the post
                message: newPostMessage,  // Include message in the post
            };
            setPosts([...posts, post]);
            setNewPostTitle('');  // Reset title input
            setNewPostMessage('');  // Reset message input
            // API call to add new post to the backend
            console.log('Adding post:', post);
            axios.post(`${process.env.REACT_APP_API_URL}/api/message-boards/communities/ydy/${boardName}/posts/`, post)
                .then(() => {
                    console.log('Post added successfully');
                })
                .catch(error => console.error('Error adding post:', error));
        }
    };

    const handleReply = (postId) => {
        if (replyText[postId]?.trim()) {
            const reply = {
                user_display_name: displayName,
                message: replyText[postId],
                parentId: postId
            };
    
            // API call to add reply to the backend
            axios.post(`${process.env.REACT_APP_API_URL}/api/message-boards/communities/ydy/${boardName}/posts/`, reply)
                .then(() => {
                    // Refetch the posts after the reply is successfully added to update the UI
                    axios.get(`${process.env.REACT_APP_API_URL}/api/message-boards/communities/ydy/${boardName}/posts`)
                        .then(response => {
                            setPosts(response.data);  // Update the posts to include the new reply
                        })
                        .catch(error => {
                            console.error('Error fetching updated posts:', error);
                        });
    
                    setReplyText(prev => ({ ...prev, [postId]: '' }));  // Clear the reply input
                    console.log('Reply added successfully');
                })
                .catch(error => {
                    console.error('Error adding reply:', error);
                });
        }
    };

    const toggleExpandPost = (postId) => {
        if (expandedPostIds.includes(postId)) {
            setExpandedPostIds(expandedPostIds.filter(id => id !== postId));
        } else {
            setExpandedPostIds([...expandedPostIds, postId]);
        }
    };

    if (!boardName) {
        return <p>Board not found.</p>;
    }

    return (
        <div className="message-board-container">
            <h1 className="board-title">
                {boardName.charAt(0).toUpperCase() + boardName.slice(1)} Board
            </h1>
            <div className="add-post-container">
                <div>Post a Mesage</div>
                <input
                    value={newPostTitle}
                    onChange={(e) => setNewPostTitle(e.target.value)}
                    placeholder="Title"
                    className="post-title-input"
                />
                <textarea
                    value={newPostMessage}
                    onChange={(e) => setNewPostMessage(e.target.value)}
                    placeholder="Message"
                    rows={4}
                    className="post-message-input"
                />
                <button className="add-post-button" onClick={handleAddPost}>Add Post</button>
            </div>
            <div className="posts-container">
                {posts.map((post, index) => (
                    <div key={index} className="post-item">
                        <div className="post-header">
                            <strong>{post.user_display_name}</strong>
                            <div className="post-title">
                                {post.title}
                            </div>
                            <button 
                                className="expand-post-button" 
                                onClick={() => toggleExpandPost(post.id)}>
                                {expandedPostIds.includes(post.id) ? 'Collapse' : 'Expand'}
                            </button>
                        </div>
                        {expandedPostIds.includes(post.id) && (
                            <>
                            <div className="post-text">
                                {post.message?.split('\n').map((line, i) => (
                                    <React.Fragment key={i}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                            <div className="replies-container">
                                {post.replies && post.replies.map((reply, index) => (
                                    <Reply
                                        key={index}
                                        reply={reply}
                                        depth={1}  // Start with depth 1 for top-level replies
                                        handleReply={handleReply}
                                        replyText={replyText}
                                        setReplyText={setReplyText}
                                        boardName={boardName}
                                    />
                                ))}
                                <textarea
                                    value={replyText[post.id] || ''}
                                    onChange={(e) => setReplyText({ ...replyText, [post.id]: e.target.value })}
                                    placeholder="Add a reply"
                                    rows={2}
                                    className="reply-input"
                                />
                                <button className="reply-button" onClick={() => handleReply(post.id)}>Reply</button>
                            </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MessageBoard;
