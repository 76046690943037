import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './modal';
import axios from 'axios';

const LandingPage = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [selectedFeature, setSelectedFeature] = useState(''); // Will track if user selects chat or a board

    useEffect(() => {
        // Check if user is already logged in
        setDisplayName(localStorage.getItem('displayName'));
    }, []);

    const handleChat = () => {
        navigate('/chat/ydy', { state: { displayName } });
    }

    const handleBoard = (board) => { 
        navigate(`/message-boards/${board}`, { state: { displayName } });
    }

    const handleEnter = () => {
        if (displayName.trim() === '') {
            alert('Please enter a display name.');
            return;
        }

        console.log(`${process.env.REACT_APP_API_URL}/api/users/${displayName}/`);
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/${displayName}/`)
            .then(() => {
                // User already exists
                if (selectedFeature === 'chat') {
                    navigate('/chat/ydy', { state: { displayName } });
                } else {
                    navigate(`/message-boards/${selectedFeature}`, { state: { displayName } });
                }
            })
            .catch(() => {
                console.log('User does not exist. Creating user...');
                const user = { username: displayName, display_name: displayName };
                axios.post(`${process.env.REACT_APP_API_URL}/api/users/`, user)
                    .then(() => {
                        if (selectedFeature === 'chat') {
                            navigate('/chat/ydy', { state: { displayName } });
                        } else {
                            navigate(`/message-boards/${selectedFeature}`, { state: { displayName } });
                        }
                    })
                    .catch(error => {
                        console.error('Error creating user:', error);
                        alert('An error occurred. Please try again.');
                    });
            });
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen -mt-32">
            <h1 className="text-4xl font-bold text-[#6699ff] mb-10">You Do You</h1>
            <h1 className="text-2xl font-semibold text-[#6699ff] mb-16">:):</h1>

            {/* Buttons for entering chat and message boards */}
            <button
                className="bg-[#6699ff] text-white px-8 py-4 rounded-full text-xl mb-4" style={{ width: '300px' }}
                onClick={() => handleChat()}
            >
                Chat
            </button>
            <button
                className="bg-[#6699ff] text-white px-8 py-4 rounded-full text-xl mb-4" style={{ width: '300px' }}
                onClick={() => handleBoard('Depression')}
            >
                Depression Board
            </button>
            <button
                className="bg-[#6699ff] text-white px-8 py-4 rounded-full text-xl mb-4" style={{ width: '300px' }}
                onClick={() => handleBoard('Anxiety')}
            >
                Anxiety Board
            </button>
            <button
                className="bg-[#6699ff] text-white px-8 py-4 rounded-full text-xl" style={{ width: '300px' }}
                onClick={() => handleBoard('Bipolar')}
            >
                Bipolar Board
            </button>
        </div>
    );
};

export default LandingPage;
