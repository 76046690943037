import React from 'react';
import { createRoot } from 'react-dom/client';  // Import createRoot from react-dom/client
import './index.css';
import './styles/tailwind.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);  // Create a root for rendering the app

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
