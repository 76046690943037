import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './login.css';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isLoginDisabled = !email || !password;
    const navigate = useNavigate();

    const handleLogin = async () => {
        const data = {
            email: email,
            password: password
        };

        try {
            const url = `${process.env.REACT_APP_API_URL}/api/auth/token/`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const responseData = await response.json();
                // Save the access token and refresh token
                localStorage.setItem('authToken', responseData.access);
                localStorage.setItem('refreshToken', responseData.refresh);
                localStorage.setItem('displayName', responseData.display_name); 

                const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${responseData.display_name}`, { method: 'GET', });
                const user = await userResponse.json();
                navigate('/landing', { state: { user } });
            } else {
                const r = await response.json();
                alert(JSON.stringify(r));
            }
        } catch (error) {
            alert('An error occurred during sign-up: ' + error);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        const token = credentialResponse.credential;

        try {
            const url = `${process.env.REACT_APP_API_URL}/api/users/google-login/`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });

            if (response.ok) {
                const responseData = await response.json();
                localStorage.setItem('authToken', responseData.token);
                localStorage.setItem('refreshToken', responseData.refresh_token);
                localStorage.setItem('displayName', responseData.display_name); 
                const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${responseData.display_name}`, { method: 'GET', });
                const user = await userResponse.json();
                navigate('/landing', { state: { user } });
            } else if (response.status === 404) {
                alert('User not found. Please sign up.');
                navigate('/sign-up', { state: { token } });
            }
            else {
                const r = await response.json();
                
                alert(JSON.stringify(r.error));
            }
        } catch (error) {
            console.error('An error occurred during Google login:', error);
        }
    };

    const handleGoogleFailure = () => {
        alert('Google login failed');
    };

    return (
        <div className="login-container">
            <div className="login-content">
                <h1 className="login-title">Loog in to You Do You</h1>
                <div className="login-info-section">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                </div>
                <button
                    className="login-btn"
                    disabled={isLoginDisabled}
                    onClick={() => handleLogin()}
                >
                    Continue
                </button>
                <div>
                    <p className="google-signup-label">Or login with Google</p>
                    <GoogleOAuthProvider clientId="250614177850-lkgag0rm2kci2qpmio3tq6rd4b72v0gq.apps.googleusercontent.com">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={handleGoogleFailure}
                        />
                    </GoogleOAuthProvider>
                </div>
                <div>
                    <p className='sign-up-label'>Don't have an account? <Link to="/sign-up" className="custom-link">Sign-Up</Link></p>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
