import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import './sign-up.css';
import defaultProfileImage from '../assets/youdoyou-logo.png';

const SignUpPage = () => {
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [profileImage, setProfileImage] = useState(null);  // For preview
    const [fileToUpload, setFileToUpload] = useState(null);  // For actual file
    const [subscription, setSubscription] = useState('standard');
    const navigate = useNavigate();

    const handleSignUp = async () => {
        const formData = new FormData();
        formData.append('display_name', displayName);
        formData.append('email', email);
        formData.append('password', password);

        // Append actual file for upload, not the preview URL
        if (fileToUpload) {
            formData.append('profile_image', fileToUpload);  // Append the actual file
        }

        try {
            const url = `${process.env.REACT_APP_API_URL}/api/users/`;
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const user = await response.json();
                localStorage.setItem('authToken', user.access);
                localStorage.setItem('refreshToken', user.refresh);
                localStorage.setItem('displayName', user.display_name);

                // Use uploaded image if available, otherwise use default image
                if (fileToUpload) {
                    const localImageURL = URL.createObjectURL(fileToUpload);
                    localStorage.setItem('profileImage', localImageURL);
                } else {
                    // Use the default profile image
                    localStorage.setItem('profileImage', defaultProfileImage);
                }

                navigate('/onboarding-flow', { state: { user } });
            } else {
                const r = await response.json();
                alert(JSON.stringify(r.error));
            }
        } catch (error) {
            alert('An error occurred during sign-up: ' + error);
        }
    };


    const handleGoogleSuccess = async (credentialResponse) => {
        const token = credentialResponse.credential;

        try {
            const formData = new FormData();
            formData.append('display_name', displayName);
            formData.append('token', token);
            // Append actual file for upload, not the preview URL
            if (fileToUpload) {
                formData.append('profile_image', fileToUpload);  // Append the actual file
            }

            const url = `${process.env.REACT_APP_API_URL}/api/users/google-sign-up/`;
            const response = await fetch(url, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log(responseData);
                localStorage.setItem('authToken', responseData.token);
                localStorage.setItem('refreshToken', responseData.refresh_token);
                localStorage.setItem('displayName', responseData.display_name);

                const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${displayName}`, { method: 'GET' });
                const user = await userResponse.json();

                navigate('/onboarding-flow', { state: { user } });
            } else {
                const r = await response.json();
                alert(JSON.stringify(r.error));
            }
        } catch (error) {
            console.error('An error occurred during Google login:', error);
        }
    };

    const handleGoogleFailure = () => {
        alert('Google login failed');
    };

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a preview URL for display purposes
            const imageUrl = URL.createObjectURL(file);
            setProfileImage(imageUrl);  // Set image preview
            setFileToUpload(file);  // Store actual file for uploading
        }
    };

    const isSignUpDisabled = !displayName || !email || !password;

    return (
        <div className="sign-up-container">
            <div className="sign-up-content">
                {/* Step 1: Display Name and Profile Image */}
                <div className="user-info-section">
                    <label>Display Name (required)</label>
                    <input
                        type="text"
                        placeholder="Display Name"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                    />
                    <div className="user-info-section">
                        <label>Profile Image</label>
                        <label htmlFor="profileImage" className="custom-file-upload">
                            Choose File
                        </label>
                        <input
                            id="profileImage"
                            type="file"
                            accept="image/*"
                            onChange={handleProfileImageChange}
                            className="file-input"
                        />
                        {profileImage && (
                            <div className="image-preview-container">
                                <img
                                    src={profileImage}
                                    alt="Profile"
                                    className="profile-image-preview"
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Step 2: Subscription Options */}
                <div className="subscription-section">
                    <div className="plan">
                        <h3><b>Standard Journey</b></h3>
                        <p style={{ marginBottom: '5px' }}>$0 / month</p>
                        <ul>
                            <li>You Do You Community</li>
                            <li>2 Video Meetings</li>
                            <li>Chat Rooms</li>
                            <li>Message Boards</li>
                            <li>Social Connections</li>
                            <li>Wellness Programs</li>
                            <li>Guided Journaling</li>
                            <li>Psychoeducation</li>
                        </ul>
                        <button
                            className={subscription === 'standard' ? 'selected' : ''}
                            onClick={() => setSubscription('standard')}
                        >
                            {subscription === 'standard' ? "Selected" : "Select"}
                        </button>
                    </div>
                    <div className="plan">
                        <h3><b>Hero's Journey</b></h3>
                        <p style={{ marginBottom: '5px' }}>$2 / month</p>
                        <ul>
                            <li>Everything in Standard</li>
                            <li>Unlimited Communities</li>
                            <li>Video Group Meetings</li>
                            <li>Direct Messaging</li>
                            <li>Private Chat Rooms</li>
                            <li>Mood Tracking</li>
                            <li>Membership Badge</li>
                            <br />
                        </ul>
                        <button
                            className={subscription === 'hero' ? 'selected' : ''}
                            onClick={() => setSubscription('hero')}
                        >
                            {subscription === 'hero' ? "Selected" : "Select"}
                        </button>
                    </div>
                </div>

                {/* Step 3: Email and Password */}
                <div className="login-info-section">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button
                    className="sign-up-btn"
                    disabled={isSignUpDisabled}
                    onClick={handleSignUp}
                >
                    Sign-Up
                </button>

                {displayName.trim() ? (
                    <div>
                        <p className="google-signup-label">Or Sign-Up with Google</p>
                        <GoogleOAuthProvider clientId="250614177850-lkgag0rm2kci2qpmio3tq6rd4b72v0gq.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={handleGoogleSuccess}
                                onError={handleGoogleFailure}
                            />
                        </GoogleOAuthProvider>
                    </div>
                ) : null}

            </div>
        </div>
    );
};

export default SignUpPage;
