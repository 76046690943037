import React, { useState } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

const Modal = ({ onClose }) => {
  const [feedback, setFeedback] = useState('');

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  function handleSubmit() {
    const feedbackData = {
        name: 'Anonymous User',
        email: 'noreply@youdoyou.org',
        message: feedback,
    };

    sendFeedback(feedbackData)
        .then(data => {
          onClose();
        })
        .catch(error => {
            alert('An error has occured: ' + error);
            onClose();
        });
}

  async function sendFeedback(feedbackData) {
    const response = await fetch(`${apiUrl}/api/feedback/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedbackData),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center modal"
      onClick={handleBackgroundClick}
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg w-1/3 modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
      >
        <h2 className="text-xl text-[#6699ff] font-bold mb-4">Thank You For Your Thoughts!</h2>
        <textarea
          className="w-full h-64 p-2 border border-gray-300 rounded"
          placeholder="Enter your feedback here..."
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <div className="flex justify-end mt-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
            onClick={handleSubmit}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
