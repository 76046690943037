import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';  // Import googleLogout
import logo from '../assets/youdoyou_logo_transparent.png';
import CommentModal from './comment-modal';
import defaultProfileImage from '../assets/youdoyou-logo.png';
import './nav-bar.css';

const NavBar = () => {
    const [showModal, setShowModal] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [profileImage, setProfileImage] = useState(defaultProfileImage);
    const [displayName, setDisplayName] = useState('');
    const [root, setRoot] = useState('/');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const storedDisplayName = localStorage.getItem('displayName');
        const storedProfileImage = localStorage.getItem('profileImage');  // Retrieve blob URL from localStorage

        setDisplayName(storedDisplayName);
        
        const root = location.pathname === '/';
        setIsLoggedIn(!!token && !root);

        // Check if there's a stored profile image in localStorage
        if (storedProfileImage) {
            setProfileImage(storedProfileImage);  // Use the cached blob URL
        } else if (isLoggedIn && profileImage === defaultProfileImage) {
            fetchProfileImage(storedDisplayName);  // Fetch image if not cached
        }

        if (displayName) {
            setRoot('/landing');
        }
    }, [isLoggedIn, location, profileImage]);

    // Fetch profile image as a blob from the server
    const fetchProfileImage = async (displayName) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${displayName}/profile-pic/`);

            if (response.ok) {
                const blob = await response.blob();  // Get the image as a blob
                const imageUrl = URL.createObjectURL(blob);  // Create a local URL for the blob
                setProfileImage(imageUrl);  // Set the blob URL as the profile image
                localStorage.setItem('profileImage', imageUrl);  // Cache the blob URL in localStorage
            } else {
                console.error('Error fetching profile image:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching profile image:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Sign out function: includes clearing local storage and Google sign-out
    const handleSignOut = () => {
        googleLogout();  // Google sign-out
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('profileImage');  // Clear cached profile image
        localStorage.removeItem('displayName');
        setIsLoggedIn(false);
        navigate('/');
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <nav className="bg-gray-800 p-4" id="navbar">
                <div className="container mx-auto flex justify-between items-center">
                    <div className="text-white text-lg">
                        <Link to={root}>
                            <img src={logo} alt="Logo" className="h-8" />
                        </Link>
                    </div>
                    <div className="text-white flex items-center">
                        <div className="mr-4">
                            {!isMobile && <label className='comments-label'>Have an idea? Leave a </label>}
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full ml-3"
                                onClick={openModal}>
                                comment
                            </button>
                        </div>
                        {isLoggedIn && (
                            <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full"
                                onClick={handleSignOut}
                            >
                                Logout
                            </button>
                        )}
                        {isLoggedIn && (
                            <Link to="/profile">
                                <img
                                    src={profileImage}
                                    alt='Profile'
                                    className="profile-picture"
                                />
                            </Link>
                        )}
                    </div>
                </div>
            </nav>

            {showModal && <CommentModal showModal={showModal} onClose={closeModal} />}
        </>
    );
};

export default NavBar;
