import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './chat-page.css';
import { useLocation } from 'react-router-dom';

const ChatPage = () => {
    const location = useLocation();
    const displayName = location.state?.displayName || 'Anonymous';
    const communityShortName = "ydy";
    const [messagesByTab, setMessagesByTab] = useState({});
    const [messageInput, setMessageInput] = useState('');
    const [activeTab, setActiveTab] = useState('');
    const [usersByTab, setUsersByTab] = useState({});
    const [availableTabs, setAvailableTabs] = useState([]);

    const inputRef = useRef(null);
    const messageDisplayRef = useRef(null);
    const chatSocketRef = useRef(null);

    const scrollToBottom = () => {
        if (messageDisplayRef.current) {
            messageDisplayRef.current.scrollTop = messageDisplayRef.current.scrollHeight;
        }
    };

    const cleanUpSocket = () => {
        if (chatSocketRef.current) {
            chatSocketRef.current.onclose = null;
            chatSocketRef.current.close();
            chatSocketRef.current = null;
        }
    };

    const establishWebSocketConnection = () => {
        if (!activeTab) {
            console.error('No active chat room selected, WebSocket connection not established.');
            return;
        }

        if (chatSocketRef.current && chatSocketRef.current.readyState !== WebSocket.CLOSED) {
            cleanUpSocket();
        }

        // Ensure we are using the correct protocol
        const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        const websocketUrl = `${protocol}${process.env.REACT_APP_WEBSOCKET_URL}/chat/communities/${communityShortName}/chatroom/${activeTab}/`;
        const socket = new WebSocket(websocketUrl);

        socket.onopen = () => {
            // Delay the join message slightly to ensure the socket is fully open
            setTimeout(() => {
                socket.send(JSON.stringify({ type: 'join', displayName }));
            }, 100);
        };

        socket.onmessage = (e) => {
            const data = JSON.parse(e.data);

            if (data.type === 'message') {
                if (displayName !== data.display_name) {
                    setMessagesByTab(prevMessages => ({
                        ...prevMessages,
                        [activeTab]: [...(prevMessages[activeTab] || []), { text: data.message, sender: data.display_name }]
                    }));
                }
                setTimeout(scrollToBottom, 50);
            } else if (data.type === 'user_list') {
                if (data.action === 'join') {
                    setUsersByTab(prevUsers => {
                        const updatedUsers = [...new Set([...(prevUsers[activeTab] || []), data.display_name])];

                        // Always move your display name to the top of the list
                        const sortedUsers = updatedUsers.filter(user => user !== displayName);
                        return {
                            ...prevUsers,
                            [activeTab]: [displayName, ...sortedUsers]
                        };
                    });
                    setTimeout(scrollToBottom, 50);
                } else if (data.action === 'leave') {
                    setUsersByTab(prevUsers => ({
                        ...prevUsers,
                        [activeTab]: (prevUsers[activeTab] || []).filter(user => user !== data.display_name)
                    }));
                }
            }
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed. Attempting to reconnect...');
            attemptReconnect();
        };

        chatSocketRef.current = socket;
    };

    const attemptReconnect = () => {
        if (!location.pathname.includes('/chat')) {
            return;
        }
        
        setTimeout(() => {
            if (!chatSocketRef.current || chatSocketRef.current.readyState === WebSocket.CLOSED) {
                console.log('Reconnecting WebSocket...');
                establishWebSocketConnection();
            }
        }, 5000);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/chat/communities/${communityShortName}/chatrooms/`)
            .then(response => {
                const rooms = response.data.map(room => room.name);
                setAvailableTabs(rooms);
                if (rooms.length > 0) {
                    setActiveTab(rooms[0]);
                }
            })
            .catch(error => {
                console.error('Error fetching chat rooms:', error);
            });
    }, []);

    useEffect(() => {
        if (!activeTab) return;

        establishWebSocketConnection();

        const fetchUsersAndMessages = async () => {
            try {
                const [usersResponse, messagesResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/api/chat/communities/${communityShortName}/chatrooms/${activeTab}/users/`),
                    axios.get(`${process.env.REACT_APP_API_URL}/api/chat/communities/${communityShortName}/chatrooms/${activeTab}/messages/`)
                ]);

                setUsersByTab(prevUsers => ({
                    ...prevUsers,
                    [activeTab]: usersResponse.data.map(user => user.display_name)
                }));

                setMessagesByTab(prevMessages => ({
                    ...prevMessages,
                    [activeTab]: messagesResponse.data.map(msg => ({
                        text: msg.message,
                        sender: msg.user_display_name === displayName ? 'You' : msg.user_display_name
                    }))
                }));

                setTimeout(scrollToBottom, 100);

            } catch (error) {
                console.error('Error fetching users or messages:', error);
            }
        };

        fetchUsersAndMessages();

        return () => {
            cleanUpSocket();
        };
    }, [activeTab, displayName]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [activeTab]);

    const handleSendMessage = () => {
        if (messageInput.trim()) {
            const message = {
                type: 'message',
                message: messageInput,
                displayName: displayName
            };

            if (chatSocketRef.current && chatSocketRef.current.readyState === WebSocket.OPEN) {
                chatSocketRef.current.send(JSON.stringify(message));
            } else {
                console.log('WebSocket connection not established. Unable to send message.');
            }

            setMessagesByTab(prevMessages => ({
                ...prevMessages,
                [activeTab]: [...(prevMessages[activeTab] || []), { text: messageInput, sender: 'You' }]
            }));
            setMessageInput('');
            setTimeout(scrollToBottom, 100);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) {
                // Allow a new line to be added to the input
                return;
            } else {
                // Send the message on Enter (without Shift)
                handleSendMessage();
                event.preventDefault();
            }
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-tabs">
                {availableTabs.map((tab) => (
                    <button
                        key={tab}
                        className={`chat-tab ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            <div className="chat-body">
                <div className="message-section">
                    <div className="message-display" ref={messageDisplayRef}>
                        {(messagesByTab[activeTab] || []).map((msg, index) => (
                            <div key={index} className={`message ${msg.sender === 'You' ? 'my-message' : 'other-message'}`}>
                                <strong>{msg.sender}: </strong>
                                {msg.text.split('\n').map((line, i) => (
                                    <React.Fragment key={i}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="message-input-container">
                        <textarea
                            value={messageInput}
                            onChange={(e) => setMessageInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="message-input"
                            ref={inputRef}
                            rows={2}  // This makes it show 2 lines initially
                        />
                        <button onClick={handleSendMessage} className="send-button">Send</button>
                    </div>
                </div>
                <div className="user-list">
                    {(usersByTab[activeTab] || []).map((user, index) => (
                        <div key={index} className="user-list-item">{user}</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChatPage;
